<script lang="ts" setup>
import { useAppStore } from '@stores/app.store'
import { computed } from 'vue'
import ImageResolver from '@components/ui/ImageResolver.vue'
import { CATEGORY_VIRTUAL_PREFIX } from '@lib/models/category'

const store = useAppStore()
const categoriesLinks = computed(() => {
  if (!store.categories.length) return []
  return store.categories
    .filter((category) => !category.idCategory.startsWith(CATEGORY_VIRTUAL_PREFIX))
    .map((category) => {
      const image = category.imageUrl || category.products?.[0].imageUrl || 'images/placeholder.svg'

      return {
        idCategory: category.idCategory,
        title: category.title,
        image
      }
    })
})
</script>

<template>
  <section
    v-if="categoriesLinks.length"
    v-dragscroll
    data-test="aside-categories"
    class="bg-white py-[0.6vh] flex flex-col gap-[0.83vh] rounded shadow-elevation-down max-h-full mcd-custom-scrollbar overflow-y-auto overflow-x-clip"
  >
    <RouterLink
      v-for="category in categoriesLinks"
      :key="category.idCategory"
      v-ripple
      data-test="aside-category-link"
      class="cursor-pointer w-full px-[1.48vw] min-h-[2.92vh] flex flex-row items-center gap-[1.48vw]"
      :to="{ name: 'category', params: { categoryId: category.idCategory } }"
    >
      <div class="relative h-[2.92vh] w-[5.19vw] min-w-[5.19vw] rounded-full bg-adk-secondaryIvory">
        <ImageResolver
          :url="category.image"
          :alt="category.title"
          class="absolute left-1/2 top-1/2 w-[4.44vw] h-auto -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <p class="text-small">{{ category.title }}</p>
    </RouterLink>
  </section>
</template>

<style scoped>
.router-link-exact-active {
  @apply relative bg-adk-secondaryIvory;
}

.router-link-exact-active::before {
  @apply absolute content-[''] h-full top-0 left-0 w-[0.25vw] bg-adk-main;
}

.router-link-exact-active p {
  @apply font-bold;
}

[data-test='aside-categories'] {
  scrollbar-gutter: auto;
}
</style>
