import { BackendService } from '../backend.service'
import { scopedLogger } from '@lib/utils/logUtils'
import { LogFunctions } from 'electron-log'
import { customerDto, LoginInput } from '@lib/models/identification'
import { zAny } from '@lib/models/common'

class IdentificationService extends BackendService {
  protected logger: LogFunctions

  constructor() {
    super('identification')

    this.logger = scopedLogger('@services/backend/identification')
  }

  async logInUser(input: LoginInput) {
    return await this.request(
      {
        method: 'POST',
        url: '/customer',
        timeout: 30 * 1000, // 30s
        data: input
      },
      customerDto,
      this.logger
    )
  }

  async upgradeLogin(input: LoginInput) {
    return await this.request(
      {
        method: 'POST',
        url: '/customer/upgrade',
        timeout: 30 * 1000, // 30s
        data: input
      },
      customerDto,
      this.logger
    )
  }

  async confirmLogin(input: LoginInput) {
    return await this.request(
      {
        method: 'POST',
        url: '/customer/confirm',
        timeout: 30 * 1000, // 30s
        data: input
      },
      customerDto,
      this.logger
    )
  }

  async logoutUser(mcId: string) {
    return await this.request(
      {
        method: 'POST',
        url: '/customer/logout',
        data: {
          mcId
        }
      },
      zAny,
      this.logger
    )
  }
}

export const identificationService = new IdentificationService()
