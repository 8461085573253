import {
  GTMEcommerceItem,
  GTMItemListName,
  GtmProductType,
  GTMProductTypes,
  GTMViewContentGroup,
  ItemSize
} from '@lib/models/gtm'
import { ProductLiteCategorized, Product, ProductSize, ProductType } from '@lib/models/product'
import { CartProduct } from '@lib/models/purchase'
import { useAppStore } from '@stores/app.store'
import get from 'lodash/get'
import { isPlaywright } from './testUtils'
import { WithdrawalMethod } from '@lib/models/order'

export const createGTMScript = (containerId: string) => {
  // special behaviour for tests
  const gtmContainerID = isPlaywright() ? 'GTM-XXXYYYY' : containerId
  return `
  (function(w,d,s,l,i){
    w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmContainerID}');`
}

export const createGTMNoScriptSrc = (containerId: string) => {
  return `https://www.googletagmanager.com/ns.html?id=${containerId}`
}

export const generatePageTitle = (content: GTMViewContentGroup) => {
  if (content.pageTitle) return content.pageTitle
  const arr = [content.cg, content.cg2, content.cg3].filter((x) => x)
  return arr.join('/')
}

export const getCustomHashLocation = () => {
  const host = 'https://adk.com'
  if (!window) return `${host}/`
  const hashPath = window.location.hash.replace('#', '') || '/'
  return `${host}${hashPath}`
}

export const getItemListNameTypeFromProducts = (
  products: GTMEcommerceItem[]
): GtmProductType | undefined => {
  let crossSellingCMS = false
  let crossSellingNBO = false
  let category
  for (const product of products) {
    if (product.item_list_name === GTMProductTypes.CROSS_SELLING_CMS) crossSellingCMS = true
    if (product.item_list_name === GTMProductTypes.CROSS_SELLING_NBO) crossSellingNBO = true
    if (crossSellingCMS && crossSellingNBO) return GTMProductTypes.CROSS_SELLING_MIX
  }
  if (crossSellingCMS) return GTMProductTypes.CROSS_SELLING_CMS
  if (crossSellingNBO) return GTMProductTypes.CROSS_SELLING_NBO
  return category
}

export const formatAmountGTM = (amount: number) => (!amount ? 0 : amount / 100)

export const formatItemSize = (size: string | undefined) => {
  if (size === 'NONE' || !size) return ItemSize.UNIQUE
  return ItemSize[size]
}

const calculatedItemListName = (productType: string): string =>
  productType === ProductType.CROSSSELLING
    ? GTMProductTypes.CROSS_SELLING_CMS
    : GTMProductTypes.CROSS_SELLING_NBO

const productPriceAmountGTM = (product: ProductLiteCategorized | Product) =>
  product.unifiedPrice?.amount
    ? formatAmountGTM(product.unifiedPrice.amount)
    : formatAmountGTM(product.price.amount)

const isLoyaltyItemList = (itemListName?: string) => itemListName === GTMItemListName.LOYALTY

export const gtmEcommerceItemFromLiteCategorized = ({
  product,
  itemListName,
  currency,
  computedUnitPrice,
  available
}: {
  product: ProductLiteCategorized
  itemListName?: string
  currency: string
  computedUnitPrice?: number
  available?: boolean
}): GTMEcommerceItem => {
  if (!itemListName) {
    itemListName = product.productType ? calculatedItemListName(product.productType) : undefined
  }
  const price = computedUnitPrice
    ? formatAmountGTM(computedUnitPrice)
    : productPriceAmountGTM(product)

  const isLoyalty = isLoyaltyItemList(itemListName)

  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: product.name,
    item_id: product.identifier,
    item_list_name: itemListName,
    size: formatItemSize(product.size),
    price: isLoyalty ? 0 : price,
    quantity: 1,
    loyalty: isLoyalty,
    points: isLoyalty ? product.points ?? 0 : 0,
    item_available: available,
    item_category: gtmEcommerceItemGetCategory(product.idCategory)
  }
}

export const gtmEcommerceItemFromSize = ({
  product,
  itemListName,
  currency,
  available
}: {
  product: ProductSize
  itemListName?: string
  currency: string
  available?: boolean
}): GTMEcommerceItem => {
  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: product.name,
    item_id: product.code,
    item_list_name: itemListName,
    size: formatItemSize(product.id),
    price: formatAmountGTM(product.price.amount),
    quantity: 1,
    loyalty: false,
    points: 0,
    item_available: available,
    item_category: gtmEcommerceItemGetCategory(product.idCategory)
  }
}

export const gtmEcommerceItemFromProduct = ({
  product,
  itemListName,
  currency
}: {
  product: Product
  itemListName?: string
  currency: string
}): GTMEcommerceItem => {
  const price = productPriceAmountGTM(product)

  const isLoyalty = isLoyaltyItemList(itemListName)

  if (!itemListName) {
    itemListName = product.productType ? calculatedItemListName(product.productType) : undefined
  }
  return {
    ...new GTMEcommerceItem(),
    currency,
    item_name: product.name,
    item_id: product.identifier,
    item_list_name: itemListName,
    is_combo: product.combo,
    size: formatItemSize(product.size),
    price: isLoyalty ? 0 : price,
    quantity: 1,
    loyalty: isLoyalty,
    points: isLoyalty ? product.points ?? 0 : 0,
    item_category: gtmEcommerceItemGetCategory(product.idCategory)
  }
}

export const gtmEcommerceItemGetCategory = (productIdCategory: string) => {
  const appStore = useAppStore()

  const productCategory = appStore.categories.find(
    (category) => category.idCategory === productIdCategory
  )

  return productCategory ? productCategory.title.toLowerCase() : ''
}

export const gtmEcommerceItemFromCartProduct = ({
  cartProduct,
  currency
}: {
  cartProduct: CartProduct
  currency: string
}): GTMEcommerceItem => {
  const price = formatAmountGTM(cartProduct.priceWithExtras)
  const isLoyalty = !!cartProduct.points

  let itemListName

  switch (true) {
    case isLoyalty:
      itemListName = GTMItemListName.LOYALTY
      break
    case !!cartProduct.productType:
      itemListName = calculatedItemListName(cartProduct.productType)
      break
    default:
      itemListName = undefined
  }

  return {
    ...new GTMEcommerceItem(),
    currency,
    item_list_name: itemListName,
    item_name: cartProduct.name,
    item_id: cartProduct.identifier,
    price: isLoyalty ? 0 : price,
    quantity: cartProduct.units,
    item_category: gtmEcommerceItemGetCategory(cartProduct.idCategory),
    loyalty: isLoyalty,
    points: isLoyalty ? cartProduct.points ?? 0 : 0
  }
}

export const extractCustomError = ({
  error,
  customHttpStatus = undefined
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
  customHttpStatus?: number
}) => {
  // defaults
  const defaultStatusCode = 500
  const defaultMessage = 'Internal Server Error'

  const errResponse = error?.response || undefined

  // status
  const statusCode = get(errResponse, 'status')

  if (!errResponse || !statusCode)
    return { code: `${defaultStatusCode}`, type: error?.name || defaultMessage }

  // messages
  const message = get(errResponse, 'data.message')
  // IM message
  const errors = get(errResponse, 'data.error', {})
  const IMErrorMessageKey = Object.keys(errors).length
    ? errors.message
    : get(errResponse, 'data.code', '')
  const stringifiedResponse = JSON.stringify(get(errResponse, 'data', defaultMessage))

  const code = customHttpStatus ?? statusCode ?? defaultStatusCode
  const type = IMErrorMessageKey || message || stringifiedResponse

  return {
    code: `${code}`,
    type
  }
}

export const buildGtmFilterAll = (filterKey: 'tier' | 'size') => {
  // size_all, points_all
  return `${filterKey.replace('tier', 'points')}_all`
}

export const buildGtmFilters = (filters?: string[]) => {
  if (!filters?.length || (filters.length === 1 && filters[0] === 'all')) return undefined
  const gtmFilters = filters.map((filter) => {
    // size:MEDIUM => size_m
    if (filter.includes('size')) {
      filter = filter.slice(0, 6)
    } else if (filter.includes('tier')) {
      // tier:120 => points_120
      filter = filter.replace('tier', 'points')
    }
    filter = filter.replace(':', '_').toLowerCase()
    return filter
  })

  return gtmFilters.join(';')
}

export const getGtmPickupType = (type: string) => {
  if (type === WithdrawalMethod.AT_TABLE) return 'table'
  else if (type === WithdrawalMethod.AT_COUNTER) return 'counter'
  return undefined
}
