import {
  CUSTOM_IM_ANALYTICS_ERRORS,
  EventDeliveryType,
  GTMEcommerceItem,
  GTMEvent,
  GtmEventName,
  GTMEventType,
  GTMItemListName,
  GTMViewContentGroup,
  LoginMethod,
  QrTypes
} from '@lib/models/gtm'
import { Order, PaymentMethod, WithdrawalMethod } from '@lib/models/order'
import { ProductLiteCategorized, Product, ProductSize } from '@lib/models/product'
import { CartProduct } from '@lib/models/purchase'
import {
  getItemListNameTypeFromProducts,
  gtmEcommerceItemFromCartProduct,
  gtmEcommerceItemFromLiteCategorized,
  gtmEcommerceItemFromProduct,
  gtmEcommerceItemFromSize,
  generatePageTitle,
  formatAmountGTM,
  getCustomHashLocation,
  getGtmPickupType
} from '@lib/utils/gtmUtils'
import { logDebug, logInfo, scopedLogger } from '@lib/utils/logUtils'
import { useAppStore } from '@stores/app.store'
import { useGtmStore } from '@stores/gtm.store'
import { computed } from 'vue'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any
  }
}
const log = scopedLogger('@composables/useGtm.ts')

export const useGtm = () => {
  const appStore = useAppStore()
  const gtmStore = useGtmStore()

  const restaurantCode = computed(() => appStore.config?.code ?? '')
  const currency = computed(() => appStore.config?.currencyAcronym ?? '')
  const cartTotals = computed(() => appStore.cartTotals)
  const cartProducts = computed(() => appStore.cart?.productsTotals || [])
  const hasBill = computed(() => Boolean(appStore.fiscalFields))
  const paymentMethod2 = computed(() => {
    const isMixedLoyalty = !!cartTotals.value.points && !!cartTotals.value.total
    if (isMixedLoyalty) return PaymentMethod.LOYALTY
    return undefined
  })

  const viewContent = computed(() => {
    return gtmStore.viewGTMContent
  })

  /* Create events general info */
  const eventGeneralInfo = (evType: GTMEventType): GTMEvent => {
    const event = new GTMEvent(evType)
    event.country = appStore.config?.country
    event.kiosk_number = appStore.config?.posNumber
    // view
    const content = viewContent.value
    if (content) {
      event.content_group = content.cg
      event.content_group2 = content.cg2
      event.content_group3 = content.cg3
      event.page_title = generatePageTitle(content) || undefined
      event.link_url = content.linkUrl
    }
    // override custom page location
    event.page_custom_location = getCustomHashLocation()
    // user
    if (appStore.user) {
      const user = appStore.user
      event.is_loyalty = user.loyalty
      event.loyalty_points = user.points
      event.user_id = user.mcId
    }
    // ecommerce
    event.ecommerce.restaurant_code = restaurantCode.value
    return event
  }

  /* Events */
  const trackGoEcommerce = () => {
    const go_ecommerce = eventGeneralInfo(GtmEventName.GO_ECOMMERCE)
    sendEvent(go_ecommerce)
  }

  const trackGoLogin = () => {
    const go_login = eventGeneralInfo(GtmEventName.GO_LOGIN)
    sendEvent(go_login)
  }

  const trackLogin = (method: string) => {
    const login = eventGeneralInfo(GtmEventName.LOGIN)
    login.method = method
    sendEvent(login)
  }

  const trackLoginError = ({ errorType, method }: { errorType: string; method: string }) => {
    const login_error = eventGeneralInfo(GtmEventName.LOGIN_ERROR)
    login_error.error_code = `${CUSTOM_IM_ANALYTICS_ERRORS.LOGIN_ERROR}`
    login_error.error_type = errorType
    login_error.method = method
    sendEvent(login_error)
  }

  const trackLogout = () => {
    const logout = eventGeneralInfo(GtmEventName.LOGOUT)
    sendEvent(logout)
  }

  const trackMenuButton = (button: string) => {
    const menu_button = eventGeneralInfo(GtmEventName.MENU(button))
    sendEvent(menu_button)
  }

  const trackGetBill = () => {
    const get_bill = eventGeneralInfo(GtmEventName.GET_BILL)
    sendEvent(get_bill)
  }

  const trackGoMym = () => {
    const go_mym = eventGeneralInfo(GtmEventName.GO_MYM)
    sendEvent(go_mym)
  }

  const trackMymHome = () => {
    const mym_home = eventGeneralInfo(GtmEventName.MYM_HOME)
    sendEvent(mym_home)
  }

  const trackViewItemList = ({
    items,
    itemListName,
    filters
  }: {
    items: GTMEcommerceItem[]
    itemListName?: string
    filters?: string
  }) => {
    const view_item_list = eventGeneralInfo(GtmEventName.VIEW_ITEM_LIST)
    // ecommerce
    view_item_list.ecommerce.currency = currency.value
    const sortedItems = items.toSorted((a, b) => {
      if (a.index && b.index) return a.index - b.index
      return 0
    })
    view_item_list.ecommerce.items = sortedItems
    view_item_list.ecommerce.item_list_name =
      itemListName ?? getItemListNameTypeFromProducts(sortedItems)
    view_item_list.ecommerce.loyalty = itemListName === GTMItemListName.LOYALTY
    view_item_list.ecommerce.filters_applied = filters
    sendEvent(view_item_list)
  }

  const trackSelectItemCategorized = ({
    item,
    isLoyalty = false,
    itemListName
  }: {
    item: ProductLiteCategorized
    isLoyalty?: boolean
    itemListName?: string
  }) => {
    const select_item = eventGeneralInfo(GtmEventName.SELECT_ITEM)
    // ecommerce
    const gtmEcommerceItem = gtmEcommerceItemFromLiteCategorized({
      product: item,
      currency: currency.value,
      itemListName: isLoyalty ? GTMItemListName.LOYALTY : undefined
    })

    if (!itemListName) {
      itemListName = isLoyalty ? GTMItemListName.LOYALTY : undefined
    }

    select_item.ecommerce.items = [gtmEcommerceItem]
    select_item.ecommerce.currency = currency.value
    select_item.ecommerce.loyalty = isLoyalty
    select_item.ecommerce.item_list_name = itemListName
    sendEvent(select_item)
  }

  const trackSelectItemSize = (item: ProductSize) => {
    const select_item = eventGeneralInfo(GtmEventName.SELECT_ITEM)
    // ecommerce
    const gtmEcommerceItem = gtmEcommerceItemFromSize({
      product: item,
      currency: currency.value
    })
    select_item.ecommerce.items = [gtmEcommerceItem]
    select_item.ecommerce.currency = currency.value
    select_item.ecommerce.loyalty = false
    sendEvent(select_item)
  }

  const trackViewItem = ({ item, isLoyalty = false }: { item: Product; isLoyalty?: boolean }) => {
    const view_item = eventGeneralInfo(GtmEventName.VIEW_ITEM)
    // ecommerce
    const gtmEcommerceItem = gtmEcommerceItemFromProduct({
      product: item,
      currency: currency.value,
      itemListName: isLoyalty ? GTMItemListName.LOYALTY : undefined
    })
    view_item.ecommerce.items = [gtmEcommerceItem]
    view_item.ecommerce.currency = currency.value
    view_item.ecommerce.loyalty = isLoyalty
    sendEvent(view_item)
  }

  const trackCustomizeItem = (product: Product) => {
    const customize_item = eventGeneralInfo(GtmEventName.CUSTOMIZE_ITEM)

    const item = gtmEcommerceItemFromProduct({
      product,
      currency: currency.value
    })

    customize_item.ecommerce.items = [item]
    sendEvent(customize_item)
  }

  const trackSelectComponent = (product: Product, componentInfo: { id: string; name: string }) => {
    const select_component = eventGeneralInfo(GtmEventName.SELECT_COMPONENT)

    const item = gtmEcommerceItemFromProduct({
      product,
      currency: currency.value
    })
    item.component_id = componentInfo.id
    item.component_name = componentInfo.name

    select_component.ecommerce.items = [item]
    sendEvent(select_component)
  }

  const trackAdditionalComponent = ({ itemId, itemName }: { itemId: string; itemName: string }) => {
    const additional_component = eventGeneralInfo(GtmEventName.ADDITIONAL_COMPONENT)

    const item = new GTMEcommerceItem()
    item.item_id = itemId
    item.item_name = itemName

    additional_component.ecommerce.items = [item]
    sendEvent(additional_component)
  }

  const trackCustomizeIngredients = (
    product: Product,
    componentInfo?: { id: string; name: string }
  ) => {
    const customize_ingredients = eventGeneralInfo(GtmEventName.CUSTOMIZE_INGREDIENTS)

    const item = gtmEcommerceItemFromProduct({
      product,
      currency: currency.value
    })
    if (componentInfo) {
      item.component_id = componentInfo.id
      item.component_name = componentInfo.name
    }
    customize_ingredients.ecommerce.items = [item]
    sendEvent(customize_ingredients)
  }

  const trackSaveIngredients = (
    product: Product,
    ingredientsEdited: string,
    componentInfo?: { id: string; name: string }
  ) => {
    const save_ingredients = eventGeneralInfo(GtmEventName.SAVE_INGREDIENTS)
    const item = gtmEcommerceItemFromProduct({
      product,
      currency: currency.value
    })
    if (componentInfo) {
      item.component_id = componentInfo.id
      item.component_name = componentInfo.name
    }
    item.ingredients_edited = ingredientsEdited
    save_ingredients.ecommerce.items = [item]
    sendEvent(save_ingredients)
  }

  // item when adding to cart only one product
  // items when modifying units in cart
  const trackAddToCart = ({
    item,
    items,
    units,
    computedUnitPrice,
    isLoyalty
  }:
    | {
        item: Product
        units: number
        computedUnitPrice: number
        isLoyalty: boolean
        items?: never
      }
    | {
        items: CartProduct[]
        isLoyalty: boolean
        item?: never
        units?: never
        computedUnitPrice?: never
      }) => {
    const add_to_cart = eventGeneralInfo(GtmEventName.ADD_TO_CART)

    if (item) {
      const gtmEcommerceItem = gtmEcommerceItemFromLiteCategorized({
        product: item,
        currency: currency.value,
        computedUnitPrice,
        itemListName: isLoyalty ? GTMItemListName.LOYALTY : undefined
      })
      gtmEcommerceItem.quantity = units
      add_to_cart.ecommerce.items = [gtmEcommerceItem]
    } else if (items) {
      add_to_cart.ecommerce.items = items.map((product) =>
        gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
      )
    }

    add_to_cart.ecommerce.currency = currency.value
    add_to_cart.ecommerce.loyalty = isLoyalty
    sendEvent(add_to_cart)
  }

  const trackGoCart = () => {
    const go_cart = eventGeneralInfo(GtmEventName.GO_CART)
    sendEvent(go_cart)
  }

  const trackViewCart = () => {
    const view_cart = eventGeneralInfo(GtmEventName.VIEW_CART)

    const items = cartProducts.value.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    view_cart.ecommerce.items = items
    view_cart.ecommerce.loyalty = !!cartTotals.value.points
    view_cart.ecommerce.points = cartTotals.value.points

    sendEvent(view_cart)
  }

  const trackRemoveFromCart = (cartProducts: CartProduct[]) => {
    const remove_from_cart = eventGeneralInfo(GtmEventName.REMOVE_FROM_CART)

    const items = cartProducts.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    remove_from_cart.ecommerce.items = items
    remove_from_cart.ecommerce.loyalty = cartProducts.some((product) => product.points)
    remove_from_cart.ecommerce.points = cartTotals.value.points

    sendEvent(remove_from_cart)
  }

  const trackApplyCoupon = () => {
    const apply_coupon = eventGeneralInfo(GtmEventName.APPLY_COUPON)
    sendEvent(apply_coupon)
  }

  const trackContinueOrdering = () => {
    const continue_ordering = eventGeneralInfo(GtmEventName.CONTINUE_ORDERING)
    sendEvent(continue_ordering)
  }

  const trackBeginCheckout = () => {
    const begin_checkout = eventGeneralInfo(GtmEventName.BEGIN_CHECKOUT)
    const items = cartProducts.value.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    begin_checkout.ecommerce.items = items
    begin_checkout.ecommerce.currency = currency.value
    begin_checkout.ecommerce.loyalty = !!cartTotals.value.points
    begin_checkout.ecommerce.points = cartTotals.value.points
    sendEvent(begin_checkout)
  }

  const trackAddShippingInfo = () => {
    const add_shipping_info = eventGeneralInfo(GtmEventName.ADD_SHIPPING_INFO)
    const items = cartProducts.value.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    add_shipping_info.ecommerce.shipping_tier = EventDeliveryType
    add_shipping_info.ecommerce.items = items
    add_shipping_info.ecommerce.currency = currency.value
    add_shipping_info.ecommerce.shipping = 0
    add_shipping_info.ecommerce.loyalty = !!cartTotals.value.points
    add_shipping_info.ecommerce.points = cartTotals.value.points
    sendEvent(add_shipping_info)
  }

  const trackAddPaymentInfo = ({ paymentType }: { paymentType: string }) => {
    const add_payment_info = eventGeneralInfo(GtmEventName.ADD_PAYMENT_INFO)
    const items = cartProducts.value.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    add_payment_info.ecommerce.shipping_tier = EventDeliveryType
    add_payment_info.ecommerce.items = items
    add_payment_info.ecommerce.currency = currency.value
    add_payment_info.ecommerce.payment_type = paymentType.toLowerCase()
    add_payment_info.ecommerce.payment_type2 = paymentMethod2.value
    add_payment_info.ecommerce.discount = formatAmountGTM(cartTotals.value.discount)
    add_payment_info.ecommerce.tax = formatAmountGTM(cartTotals.value.tax)
    add_payment_info.ecommerce.shipping = 0
    add_payment_info.ecommerce.bill = hasBill.value
    add_payment_info.ecommerce.loyalty = !!cartTotals.value.points
    add_payment_info.ecommerce.points = cartTotals.value.points
    sendEvent(add_payment_info)
  }

  const trackOrderFailed = ({
    code,
    type,
    paymentMethod
  }: {
    code: string
    type: string
    paymentMethod
  }) => {
    const order_failed = eventGeneralInfo(GtmEventName.ORDER_FAILED)
    order_failed.error_code = code
    order_failed.error_type = type.toLowerCase()
    order_failed.ecommerce.payment_type = paymentMethod.toLowerCase()
    order_failed.ecommerce.payment_type2 = paymentMethod2.value
    sendEvent(order_failed)
  }

  const trackPurchase = ({
    order,
    eventOrigin = 'adk'
  }: {
    order: Order
    eventOrigin?: string
  }) => {
    const getRemainingPointsAfterPurchase = (orderPoints: number) => {
      if (!appStore.user) return undefined
      if (!orderPoints) return appStore.user.points
      return appStore.user.points - orderPoints
    }

    const purchase = eventGeneralInfo(GtmEventName.PURCHASE)
    const items = cartProducts.value.map((product) =>
      gtmEcommerceItemFromCartProduct({ cartProduct: product, currency: currency.value })
    )
    const orderTotals = cartTotals.value
    purchase.ecommerce.shipping_tier = EventDeliveryType
    purchase.ecommerce.items = items
    purchase.ecommerce.currency = currency.value
    purchase.ecommerce.payment_type = order.paymentMethod.toLowerCase()
    purchase.ecommerce.payment_type2 = paymentMethod2.value
    purchase.ecommerce.transaction_id = order.orderId
    purchase.ecommerce.discount = formatAmountGTM(orderTotals.discount)
    purchase.ecommerce.tax = formatAmountGTM(orderTotals.tax)
    purchase.ecommerce.shipping = 0
    purchase.ecommerce.value = formatAmountGTM(orderTotals.total)
    purchase.ecommerce.bill = hasBill.value
    const points = orderTotals.points
    purchase.ecommerce.loyalty = !!points
    purchase.ecommerce.points = points
    purchase.ecommerce.points_spent = points
    // loyalty_points has plain user points, now we need to calculate remaining points with current order
    purchase.loyalty_points = getRemainingPointsAfterPurchase(points)
    purchase.ecommerce.pickup_type = getGtmPickupType(order.area ?? WithdrawalMethod.AT_COUNTER)
    purchase.event_origin = eventOrigin
    sendEvent(purchase)
  }

  const trackSelectPickUpSelector = (selector: string) => {
    const select_pickup_selector = eventGeneralInfo(GtmEventName.SELECT_PICKUP_SELECTOR(selector))
    sendEvent(select_pickup_selector)
  }

  const trackSelectPickUp = (pickupType: string) => {
    const select_pickup = eventGeneralInfo(GtmEventName.SELECT_PICKUP)
    select_pickup.ecommerce.pickup_type = getGtmPickupType(pickupType)
    sendEvent(select_pickup)
  }

  const trackOrderCancelledBefore = () => {
    const order_cancelled_before = eventGeneralInfo(GtmEventName.ORDER_CANCELLED_BEFORE)
    sendEvent(order_cancelled_before)
  }

  const trackRedeem = ({ coupon, method }: { coupon: string; method: string }) => {
    const redeem = eventGeneralInfo(GtmEventName.REDEEM)
    //ecommerce
    redeem.method = method
    if (method === LoginMethod.QR_IDENTIFICATION) redeem.qr_type = QrTypes.COUPON
    redeem.ecommerce.currency = currency.value
    redeem.ecommerce.coupon = coupon
    sendEvent(redeem)
  }

  const trackCustomError = ({ code, type }: { code: string; type: string }) => {
    const custom_error = eventGeneralInfo(GtmEventName.CUSTOM_ERROR)
    custom_error.error_code = code
    custom_error.error_type = type
    sendEvent(custom_error)
  }

  const trackInactivityPopup = () => {
    const inactivity_popup = eventGeneralInfo(GtmEventName.INACTIVITY_POPUP)
    sendEvent(inactivity_popup)
  }

  const trackInactivityExit = () => {
    const inactivity_exit = eventGeneralInfo(GtmEventName.INACTIVITY_EXIT)
    sendEvent(inactivity_exit)
  }

  const trackSelectFilter = (type: string) => {
    const select_filter = eventGeneralInfo(GtmEventName.SELECT_FILTER)
    select_filter.type = type
    sendEvent(select_filter)
  }

  /* PageView */
  const trackPageView = (content: GTMViewContentGroup, modal = false) => {
    gtmStore.setViewGTMContent(content, modal)
    const page_view = eventGeneralInfo(GtmEventName.PAGE_VIEW)

    sendEvent(page_view)
  }

  const trackOnCloseModal = () => {
    const previousPageViewContent = gtmStore.pageViewGTMContent
    if (previousPageViewContent) {
      trackPageView(previousPageViewContent)
    }
  }

  /* Send events method */
  const sendEvent = (ev: GTMEvent) => {
    if (!window) logInfo(log, `Event on server, will not be sended [${ev.event}]`)
    else {
      if (!window.dataLayer) window.dataLayer = []
      window.dataLayer.push(ev)
      logInfo(log, `New event pushed to datalayer [${ev.event}]`)
      logDebug(log, ev as unknown as Record<string, unknown>)
    }
  }

  return {
    // pageView
    trackPageView,
    trackOnCloseModal,
    // events
    trackGoEcommerce,
    trackGoLogin,
    trackLogin,
    trackLoginError,
    trackLogout,
    trackMenuButton,
    trackGetBill,
    trackGoMym,
    trackMymHome,
    trackViewItemList,
    trackSelectItemCategorized,
    trackSelectItemSize,
    trackViewItem,
    trackCustomizeItem,
    trackSelectComponent,
    trackAdditionalComponent,
    trackCustomizeIngredients,
    trackSaveIngredients,
    trackAddToCart,
    trackGoCart,
    trackViewCart,
    trackRemoveFromCart,
    trackApplyCoupon,
    trackContinueOrdering,
    trackBeginCheckout,
    trackAddShippingInfo,
    trackAddPaymentInfo,
    trackOrderFailed,
    trackPurchase,
    trackSelectPickUpSelector,
    trackSelectPickUp,
    trackOrderCancelledBefore,
    trackCustomError,
    trackRedeem,
    trackInactivityPopup,
    trackInactivityExit,
    trackSelectFilter
  }
}
