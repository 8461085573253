import { OptionsGroupsType } from '@lib/models/common'
import {
  OptionChild,
  OptionMain,
  OptionsGroupsChild,
  OptionsGroupsMain
} from '@lib/models/optionGroups'
import {
  ProductLite,
  ProductLiteCategorized,
  ProductSize,
  SizeId,
  SizeType
} from '@lib/models/product'
import { Cart } from '@lib/models/purchase'

export const getOptionsGroupsToCustomize = (
  optionsGroups: OptionsGroupsMain[],
  isLoyalty: boolean
) => {
  const filterAvailableOptions = (opt: OptionMain | OptionChild) => opt.available

  const productOptionsGroups = structuredClone(optionsGroups)
  const mappedOptionsGroups = productOptionsGroups.map((optGM) => {
    // filter first level options and 3º level inside
    const options = optGM.options.filter(filterAvailableOptions)

    // when loyalty and CHOICE only take options with minimun price
    const filteredOptions = isLoyalty
      ? filterLoyaltyChoiceMinPriceOptions<OptionMain>({ optionGroupType: optGM.type, options })
      : options

    const finalOptions = filteredOptions.map((optionM) => {
      if (!optionM.optionsGroups.length) return optionM
      // loop 3º level only taking available option
      return {
        ...optionM,
        optionsGroups: optionM.optionsGroups.map((optGChild) => {
          const optionsThirdLevel = optGChild.options.filter(filterAvailableOptions)

          // when loyalty and CHOICE only take options with minimum price
          const filteredOptionsThirdLevel = isLoyalty
            ? filterLoyaltyChoiceMinPriceOptions<OptionChild>({
                optionGroupType: optGChild.type,
                options: optionsThirdLevel
              })
            : optionsThirdLevel

          return {
            ...optGChild,
            options: filteredOptionsThirdLevel
          }
        })
      }
    })

    // autoselect CHOICE group having only one option available
    if (optGM.type === OptionsGroupsType.CHOICE && finalOptions.length === 1) {
      const selectedOption = { ...finalOptions[0], units: 1 }
      return {
        ...optGM,
        options: [selectedOption]
      }
    }
    return { ...optGM, options: finalOptions }
  })
  return mappedOptionsGroups
}

export const getAllOptions = (optionsGroups: OptionsGroupsMain[]): OptionMain[] => {
  return optionsGroups.reduce(
    (prev: OptionMain[], cur) => prev.concat(getFilteredOptionsOnlySelectedMain(cur) || []),
    []
  )
}
export const getAllThirdLevelOptions = (optionsGroups: OptionsGroupsMain[]): OptionChild[] => {
  const allOptions = getAllOptions(optionsGroups)
  const allThirdLevelGroups: OptionsGroupsChild[] = allOptions.reduce(
    (prev: OptionsGroupsChild[], cur) => prev.concat(cur.optionsGroups || []),
    []
  )
  return (
    allThirdLevelGroups
      .reduce(
        (prev: OptionChild[], cur) => prev.concat(getFilteredOptionsOnlySelectedChild(cur) || []),
        []
      )
      // filter leave 4º level options not present already in allOptions
      .filter((optChild) => !allOptions.some((opt) => opt.identifier === optChild.identifier))
  )
}

export const filterLoyaltyChoiceMinPriceOptions = <T extends OptionMain | OptionChild>({
  optionGroupType,
  options
}: {
  optionGroupType: string
  options: Array<T>
}): Array<T> => {
  if (!options.length) return []
  if (optionGroupType !== OptionsGroupsType.CHOICE) return options

  const minPrice = options.reduce((min, opt) => {
    const price = opt.price.amount ?? 0
    return price < min ? price : min
  }, options[0].price.amount ?? 0)

  return options.filter((opt) => opt.price.amount === minPrice)
}

export const getOptionExtraAmount = (option: OptionMain | OptionChild): number => {
  const extra = option.units - option.qty
  return extra > 0 ? extra * option.price?.amount : 0
}

export const getOptionsGroupsPrice = () => {}

export const getProductExtrasAmount = (optionsGroups: OptionsGroupsMain[]) => {
  if (!optionsGroups.length) return 0
  const allOptions = getAllOptions(optionsGroups)
  const allThirdLevelOptions = getAllThirdLevelOptions(optionsGroups)

  const optionsBill: number[] = allOptions.map((option) => getOptionExtraAmount(option))
  const thirdLevelBill: number[] = allThirdLevelOptions.map((option) =>
    getOptionExtraAmount(option)
  )
  const optionsAmount = optionsBill.reduce((prev, cur) => prev + cur, 0)
  const thirdLevelAmount = thirdLevelBill.reduce((prev, cur) => prev + cur, 0)
  return optionsAmount + thirdLevelAmount
}

export const getProductTotalAmount = ({
  optionsGroups,
  unitPriceAmount,
  unifiedPriceAmount
}: {
  optionsGroups: OptionsGroupsMain[]
  unitPriceAmount: number
  unifiedPriceAmount?: number
}) => {
  let extras = 0
  if (!unifiedPriceAmount) extras = getProductExtrasAmount(optionsGroups)
  else {
    // when unifiedPrice product base is 0
    // compute here like almost one option of product is selected (one with minimun value) to compute at least unifiedPrice.amount
    const prodOptGroupsTouched = optionsGroups.map((optGM) => {
      if (optGM.type !== OptionsGroupsType.CHOICE) return optGM
      if (!optGM.options.length || optGM.options.some((optM) => optM.units === 1)) return optGM
      const optionsSortedMinValue = optGM.options.toSorted(
        (a, b) => a.price.amount - b.price.amount
      )
      optionsSortedMinValue[0].units = 1
      return {
        ...optGM,
        options: optionsSortedMinValue
      }
    })
    extras = getProductExtrasAmount(prodOptGroupsTouched)
  }
  const base = unitPriceAmount || 0
  return base + extras
}

export const getProductTotalUnitsAmount = ({
  optionsGroups,
  unitPriceAmount,
  unifiedPriceAmount,
  units
}: {
  optionsGroups: OptionsGroupsMain[]
  unitPriceAmount: number
  unifiedPriceAmount?: number
  units: number
}) => {
  return (
    getProductTotalAmount({
      optionsGroups,
      unitPriceAmount,
      unifiedPriceAmount
    }) * units
  )
}

// In optionsGroup = CHOICE return options filtered with selected option
export const getFilteredOptionsOnlySelected = (
  optGroup: OptionsGroupsMain | OptionsGroupsChild
): OptionMain[] | OptionChild[] =>
  optGroup.type === OptionsGroupsType.CHOICE
    ? optGroup.options.filter((opt) => opt.units === 1)
    : optGroup.options

export const getFilteredOptionsOnlySelectedMain = (optGroup: OptionsGroupsMain): OptionMain[] =>
  getFilteredOptionsOnlySelected(optGroup) as OptionMain[]

export const getFilteredOptionsOnlySelectedChild = (optGroup: OptionsGroupsChild): OptionChild[] =>
  getFilteredOptionsOnlySelected(optGroup) as OptionChild[]

/* Product Extras resume */
export const getSingleOptionCustomization = ({
  option,
  group,
  hideCondiment
}: {
  option: OptionMain | OptionChild
  group: {
    isExtraGroup: boolean
    isComponentGroup: boolean
    isCondimentGroup: boolean
    isCommentGroup: boolean
  }
  hideCondiment: boolean
}) => {
  const componentOption = (option: OptionMain | OptionChild) => {
    // is a max 1 option deselected
    if (isCheckboxOption && option.units < option.qty) {
      return `<del>${option.name}</del>`
    } else {
      // regular component
      const isDiffering = option.units !== option.qty
      const differingQty = option.units - option.qty
      if (isDiffering) {
        if (differingQty > 0) return `${differingQty} ${option.name}`
        else {
          const qty = Math.abs(differingQty)
          return `<del>${qty} ${option.name}</del>`
        }
      }
    }
    return ''
  }

  const { isComponentGroup, isExtraGroup, isCondimentGroup, isCommentGroup } = group
  let acc = ''
  const isCheckboxOption = option.max === 1 && option.min === 0

  if (isComponentGroup) {
    acc += componentOption(option)
  } else if (isExtraGroup || (!hideCondiment && isCondimentGroup)) {
    const differingQty = option.units > option.qty ? option.units - option.qty : 0
    if (differingQty) acc += `${differingQty} ${option.name}`
  } else if (isCommentGroup) {
    if (isCheckboxOption && option.units < option.qty) {
      acc += `<del>${option.name}</del>`
    } else if (option.units > option.qty) {
      acc += option.name
    }
  }

  return acc
}

const getSingleOptionCustomizationGTM = ({
  option,
  group,
  hideCondiment
}: {
  option: OptionMain | OptionChild
  group: {
    isExtraGroup: boolean
    isComponentGroup: boolean
    isCondimentGroup: boolean
    isCommentGroup: boolean
  }
  hideCondiment: boolean
}) => {
  const componentOption = (option: OptionMain | OptionChild, optionName: string) => {
    // is a max 1 option deselected
    if (isCheckboxOption && option.units < option.qty) {
      return `${optionName}_${option.units}`
    } else {
      // regular component
      const isDiffering = option.units !== option.qty
      const differingQty = option.units - option.qty
      if (isDiffering) {
        if (differingQty > 0) return `${optionName}_${differingQty}`
        else {
          const qty = Math.abs(differingQty)
          return `${optionName}_${qty}`
        }
      }
    }
    return ''
  }

  const { isComponentGroup, isExtraGroup, isCondimentGroup, isCommentGroup } = group
  let acc = ''
  const isCheckboxOption = option.max === 1 && option.min === 0

  const optionName = option.name.replaceAll(' ', '_').toLowerCase()

  if (isComponentGroup) {
    acc += componentOption(option, optionName)
  } else if (isExtraGroup || (!hideCondiment && isCondimentGroup)) {
    const differingQty = option.units > option.qty ? option.units - option.qty : 0
    if (differingQty) acc += `${optionName}_${differingQty}`
  } else if (isCommentGroup && option.units !== option.qty) {
    acc += `${optionName}_${option.units}`
  }

  return acc
}

const getOptionsCustomization = ({
  optG,
  hideCondiment,
  joinChar
}: {
  optG: OptionsGroupsMain | OptionsGroupsChild
  hideCondiment: boolean
  joinChar: string
}) => {
  const isExtraGroup = optG.type === OptionsGroupsType.CANADD
  const isComponentGroup = optG.type === OptionsGroupsType.COMPONENT
  const isCondimentGroup = optG.type === OptionsGroupsType.CONDIMENT
  const isCommentGroup = optG.type === OptionsGroupsType.COMMENT

  const prefix = isExtraGroup ? `${'{common.extra}'} ` : ''

  // Only loop trough selected options not all in CHOICE optionsGroup
  const filteredOptions = getFilteredOptionsOnlySelected(optG)

  const optionTexts = filteredOptions
    .map((curr: OptionMain | OptionChild) =>
      getSingleOptionCustomization({
        option: curr,
        group: {
          isExtraGroup,
          isComponentGroup,
          isCondimentGroup,
          isCommentGroup
        },
        hideCondiment
      })
    )
    .filter((t) => t)

  const text = optionTexts.join(joinChar)
  return text ? `${prefix} ${text}` : ''
}

export const getProductCustomizationResume = ({
  optsGroups,
  joinChar = ', ',
  hideCondiment = false
}: {
  optsGroups: OptionsGroupsMain[] | OptionsGroupsChild[]
  joinChar?: string
  hideCondiment?: boolean
}) => {
  const optGroupsTexts = optsGroups
    .map((optGM: OptionsGroupsMain | OptionsGroupsChild) => {
      const optionsText = getOptionsCustomization({ optG: optGM, hideCondiment, joinChar })
      return optionsText
    })
    .filter((t) => t)
  return optGroupsTexts.join(joinChar)
}

export const getProductCustomizationResumeGtm = ({
  optsGroups,
  joinChar = ';',
  hideCondiment = false
}: {
  optsGroups: OptionsGroupsMain[] | OptionsGroupsChild[]
  joinChar?: string
  hideCondiment?: boolean
}) => {
  const getOptionsCustomization = (optG: OptionsGroupsMain | OptionsGroupsChild) => {
    const isExtraGroup = optG.type === OptionsGroupsType.CANADD
    const isComponentGroup = optG.type === OptionsGroupsType.COMPONENT
    const isCondimentGroup = optG.type === OptionsGroupsType.CONDIMENT
    const isCommentGroup = optG.type === OptionsGroupsType.COMMENT

    // Only loop trough selected options not all in CHOICE optionsGroup
    const filteredOptions = getFilteredOptionsOnlySelected(optG)

    const optionTexts = filteredOptions
      .map((curr: OptionMain | OptionChild) =>
        getSingleOptionCustomizationGTM({
          option: curr,
          group: {
            isExtraGroup,
            isComponentGroup,
            isCondimentGroup,
            isCommentGroup
          },
          hideCondiment
        })
      )
      .filter((t) => t)

    return optionTexts.join(joinChar)
  }

  const optGroupsTexts = optsGroups
    .map((optGM) => {
      const optionsText = getOptionsCustomization(optGM)
      return optionsText
    })
    .filter((t) => t)
  return optGroupsTexts.join(joinChar)
}

export const getOptionGroupCartCustomization = ({
  optGMain,
  fullChoiceCustomization = false
}: {
  optGMain: OptionsGroupsMain
  fullChoiceCustomization: boolean
}) => {
  let customizationOptGroup = ''
  let groupName = ''
  let isOptionSelected = false
  const isChoiceGroup = optGMain.type === OptionsGroupsType.CHOICE
  if (isChoiceGroup) {
    const optionSelected = optGMain.options.find((opt) => opt.units === 1)
    if (optionSelected) {
      isOptionSelected = true
      groupName = optionSelected?.name
      // Get customization of optionSelected.optionsGroups or not
      if (fullChoiceCustomization) {
        customizationOptGroup = getProductCustomizationResume({
          optsGroups: optionSelected.optionsGroups,
          joinChar: ' '
        })
      }
    }
  } else {
    customizationOptGroup = getProductCustomizationResume({
      optsGroups: [optGMain],
      joinChar: ' '
    })
    groupName = optGMain.title
  }

  if (isChoiceGroup && isOptionSelected && !customizationOptGroup) return `${groupName}`
  else if (customizationOptGroup)
    return `${isOptionSelected ? groupName : ''} ${customizationOptGroup}`

  return undefined
}

export const getCartCustomization = ({
  optGroups,
  fullChoiceCustomization = false
}: {
  optGroups: OptionsGroupsMain[]
  fullChoiceCustomization: boolean
}) => {
  const texts: string[] = []
  for (const optGMain of optGroups) {
    const optGText = getOptionGroupCartCustomization({ optGMain, fullChoiceCustomization })
    if (optGText) texts.push(optGText)
  }
  return texts.join(' - ')
}

export const filterBarcodeProducts = (product: ProductLite | ProductLiteCategorized) =>
  !product.barcode

/* Sizes */

export const getLargestSizeAvailable = (sizes: ProductSize[]): SizeType => {
  const numValue = {
    [SizeId.NONE]: 0,
    [SizeId.SMALL]: 1,
    [SizeId.MEDIUM]: 2,
    [SizeId.LARGE]: 3
  }
  return sizes.reduce((acc, curr) => {
    const currentNumeration = numValue[curr.id]
    if (currentNumeration > numValue[acc]) acc = curr.id
    return acc
  }, SizeId.NONE as SizeType)
}

// sort sizes L => M => P => NONE
export const sortSizes = (sizes: ProductSize[]) =>
  sizes.toSorted((a, b) => {
    if (a.id === SizeId.LARGE) return -1
    else if (b.id === SizeId.LARGE) return 1
    if (a.id === SizeId.MEDIUM) return -1
    else if (b.id === SizeId.MEDIUM) return 1
    if (a.id === SizeId.SMALL) return -1
    else if (b.id === SizeId.SMALL) return 1
    if (a.id === SizeId.NONE) return -1
    else if (b.id === SizeId.NONE) return 1
    return 0
  })

export const orderedAndUniqueProductList = (cart: Cart | undefined): string => {
  const productListOrdered = cart
    ? cart.productsTotals.map((product) => product.identifier).sort((a, b) => Number(a) - Number(b))
    : []
  return [...new Set(productListOrdered)].join(',')
}
