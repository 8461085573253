import { z } from 'zod'
import { productLiteSchema, SizeType } from './product'
import { localImageSchema } from './common'
import { Filters } from './internal/filters'

export const CATEGORY_VIRTUAL_PREFIX = '__:'

export const categoryDto = z.object({
  idCategory: z.string(),
  title: z.string(),
  imageUrl: localImageSchema,
  products: z.array(productLiteSchema)
})

export type Category = z.infer<typeof categoryDto>

export type CategoryFilter = {
  [Filters.SIZE]?: Array<SizeType>
}

export const categoriesDto = z.array(categoryDto)

export const categoriesNamesWithSizeFilters = [
  'McCombos',
  'Signature Collection',
  'Ensaladas',
  'Bebidas',
  'McCafé tradicional',
  'McCafé premium'
].map((val) => val.toLowerCase())
