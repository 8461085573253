import { useAppStore } from '@stores/app.store'
import { computed } from 'vue'

export const useLoyaltyPoints = () => {
  const store = useAppStore()

  const spentPoints = computed(() => store.cartTotals.points)

  const pointsEquivalence = computed(() => store.config?.loyaltyPointsEquivalence ?? 100)

  const maxAvailablePoints = computed(() => {
    if (!store.user?.points) return 0
    const userPoints = store.user.points
    return userPoints - spentPoints.value
  })

  const computeEstimatedPointsReward = (amount: number) => {
    const realAmount = amount / 100
    return Math.floor(realAmount / pointsEquivalence.value)
  }

  return { spentPoints, maxAvailablePoints, pointsEquivalence, computeEstimatedPointsReward }
}
