export const BackendCustomException = {
  601: 'outageError',
  610: 'aopCouponAlreadyUsed',
  611: 'aopCouponExpired'
} as const

export type CustomExceptionKey =
  | (typeof BackendCustomException)[keyof typeof BackendCustomException]
  | 'unauthorized'
  | 'unknown'
