import { z } from 'zod'
import { productLiteCategorized } from './product'
import { Filters } from './internal/filters'

/* Loyalty */
const loyaltyTier = z.object({
  tier: z.number(),
  products: z.array(productLiteCategorized)
})

export type LoyaltyTier = z.infer<typeof loyaltyTier>

export const loyaltyTiers = z.array(loyaltyTier)

export type LoyaltyFilter = {
  [Filters.TIER]?: Array<number>
}
