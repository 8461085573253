import { AttractImages, Configuration, FiscalFieldsName } from '@lib/models/config'
import { Block } from '@lib/models/blocks'
import { Cart } from '@lib/models/purchase'
import { Category } from '@lib/models/category'
import { scopedLogger, logInfo, logError } from '@lib/utils/logUtils'
import { defineStore } from 'pinia'
import { purchaseService } from '@lib/services/backend/purchase/purchase.service'
import { SaleType, PriceTotals } from '@lib/models/common'
import { ProductLiteCategorized } from '@lib/models/product'
import { FiscalFields } from '@lib/models/internal/checkout'
import { LoginInput, LoginType, User } from '@lib/models/identification'
import { identificationService } from '@lib/services/backend/identification/identification.service'
import { capitalizeName } from '@lib/utils/formatUtils'
import { useGtm } from '@composables/useGtm'
import { LoginMethod } from '@lib/models/gtm'
import { removeAllAnalyticsCookies } from '@lib/utils/utils'
import { LoyaltyTier } from '@lib/models/loyalty'
import { catalogService } from '@lib/services/backend/catalog/catalog.service'
import { ordersService } from '@lib/services/backend/orders/orders.service'

const log = scopedLogger('@stores/app.store.ts')

interface State {
  locale: string
  config?: Configuration
  categories: Array<Category>
  accessibility: boolean
  resetADK: boolean
  // view
  viewCategory?: Category
  // content
  homeBlocks?: Array<Block>
  // crossSelling
  crossSellingCheckoutIsViewed: boolean
  crossSellingProducts?: Array<ProductLiteCategorized>
  lastProductList: string
  // purchaseSession
  cart?: Cart
  fiscalFields?: FiscalFields
  cardMethodUnavailable: boolean
  // user
  user?: User
  softUserValidationImIdentity: boolean
  confirmingIdentity: boolean
  inactivityIsShowed: boolean
  // aop coupons
  aopCouponProducts?: ProductLiteCategorized[]
  // loyalty
  loyaltyTiers: Array<LoyaltyTier> | undefined
}

export const useAppStore = defineStore('app', {
  state: (): State => ({
    locale: '',
    config: undefined,
    categories: [],
    accessibility: false,
    resetADK: false,
    viewCategory: undefined,
    homeBlocks: undefined,
    crossSellingCheckoutIsViewed: false,
    crossSellingProducts: undefined,
    cart: undefined,
    fiscalFields: undefined,
    cardMethodUnavailable: false,
    softUserValidationImIdentity: false,
    confirmingIdentity: false,
    inactivityIsShowed: false,
    loyaltyTiers: undefined,
    lastProductList: ''
  }),
  getters: {
    cartTotal(): number {
      if (!this.cart) return 0
      return this.cart.totals.total
    },
    cartTotals(): PriceTotals {
      if (!this.cart)
        return {
          total: 0,
          subtotal: 0,
          tax: 0,
          discount: 0,
          points: 0
        }
      return this.cart.totals
    },
    cartUnits(): number {
      if (!this.cart) return 0
      return this.cart.productsTotals.reduce((acc, prodGroup) => {
        acc += prodGroup.units
        return acc
      }, 0)
    },
    attractImages(): AttractImages | undefined {
      if (!this.config) return undefined
      return this.config?.attractScreen.images
    },
    documentMask(): string {
      if (!this.config?.dynamicFiscalFields) return ''
      const typeFieldOptions = this.config.dynamicFiscalFields.find(
        (fiscalField) => fiscalField.code === FiscalFieldsName.DOCUMENT_TYPE
      )?.options

      const mask = typeFieldOptions?.find(
        (data) => data.defaultLoyaltyDocument && data.loyaltyDocument
      )?.mask

      return mask ?? ''
    },
    currencyAcronym(): string {
      return this.config?.currencyAcronym ?? ''
    },
    isUserFullExp(): boolean {
      return this.user?.loginType === LoginType.IM
    }
  },
  actions: {
    async executeResetADK() {
      if (this.cart?.sessionId) {
        try {
          // end purchaseSession & dispose order (if needed in back)
          await this.endPurchaseSession(this.cart.sessionId)
        } catch (error) {
          this.setCart() // to undefined
        }
      }
      // reset store
      this.homeBlocks = undefined
      this.crossSellingProducts = undefined
      this.crossSellingCheckoutIsViewed = false
      this.lastProductList = ''
      this.accessibility = false
      this.cardMethodUnavailable = false
      this.loyaltyTiers = undefined
      // user related
      await this.clearUserData({ cleanSession: false })
      this.softUserValidationImIdentity = false
      this.confirmingIdentity = false
      this.inactivityIsShowed = false
      logInfo(log, 'RESETTED ADK STATE succesfully')
      // remove _ga and clarity cookies
      removeAllAnalyticsCookies()
      this.resetADK = false
    },
    setConfiguration(config?: Configuration) {
      this.config = config
      logInfo(log, 'Setted configuration')
    },
    setCategories(categories: Array<Category>) {
      this.categories = categories
      logInfo(log, 'Setted categories')
    },
    setAccessibility(val: boolean) {
      this.accessibility = val
    },
    // views
    setViewCategory(category?: Category) {
      this.viewCategory = category
    },
    // content
    setHomeBlocks(homeBlocks: Array<Block>) {
      this.homeBlocks = homeBlocks
    },
    // crossSelling
    setCrossSellingProducts(products: Array<ProductLiteCategorized>, lastProductList: string) {
      this.crossSellingProducts = products
      this.lastProductList = lastProductList
    },
    setCrossSellingCheckoutIsViewed() {
      this.crossSellingCheckoutIsViewed = true
    },

    // purchaseSession
    setCart(cart?: Cart) {
      this.cart = cart
      logInfo(log, 'Setted cart')
    },
    async initPurchaseSession(saleType: SaleType) {
      logInfo(log, 'fetch purchaseSession start')
      try {
        this.cart = await purchaseService.fetchStartPurchaseSession(saleType)
        logInfo(
          log,
          'purchaseSession started with success, sessionId = ' +
            this.cart.sessionId +
            ', saleType = ' +
            this.cart.saleType
        )
      } catch (error) {
        logInfo(log, 'Something was wrong on starting purchaseSession')
        throw error
      }
    },
    async endPurchaseSession(sessionId: string) {
      logInfo(log, 'fetch purchaseSession end')
      try {
        await purchaseService.fetchEndPurchaseSession(sessionId)
        this.setCart() // to undefined
        logInfo(log, 'purchaseSession ended with success')
        logInfo(log, `Disposing order after ended session, with id = {${sessionId}}`)
        // will not await
        ordersService
          .fetchDisposeOrder(sessionId)
          .then((res) => {
            logInfo(
              log,
              `Try to dispose order={${sessionId}} async succesfully: ` + JSON.stringify(res ?? {})
            )
          })
          .catch((err) => {
            logError(
              log,
              `Some error in disposing order={${sessionId}} async : ` +
                (err?.response?.data ? JSON.stringify(err.response.data) : err.message)
            )
          })
      } catch (error) {
        throw new Error('purchaseSession could not be ended')
      }
    },
    setFiscalFields(fiscalFields?: FiscalFields) {
      this.fiscalFields = fiscalFields
      logInfo(log, 'Setted fiscal fields => ' + JSON.stringify(fiscalFields || {}))
    },
    setUser({ user }: { user?: User }) {
      if (user) {
        user.name = capitalizeName(user.name)
      }
      this.user = user
    },
    async logInUserAction(input: LoginInput) {
      const { trackLoginError } = useGtm()
      try {
        const user = await identificationService.logInUser(input)
        this.setUser({ user })
        return this.user
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        trackLoginError({
          errorType: error.message || 'RequestFailed',
          method: LoginMethod.QR_IDENTIFICATION
        })
        logInfo(log, 'User data could not be obtained')
        return undefined
      }
    },
    async clearUserData({ cleanSession = true }) {
      this.fiscalFields = undefined
      // logout user previously
      if (this.user) {
        const mcId = this.user.mcId
        try {
          await identificationService.logoutUser(mcId)
          logInfo(log, `Logout user succesfully with mcId = {${mcId}}`)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
          logError(
            log,
            `Some error in logout with mcId = {${mcId}}: ` + err?.response?.data
              ? JSON.stringify(err.response.data)
              : err.message
          )
        }
      }
      this.setUser({ user: undefined })
      this.inactivityIsShowed = false

      if (cleanSession && (this.cart?.coupons.length || this.cart?.totals.points)) {
        // clear coupons and loyalty products from purchaseSession
        try {
          logInfo(log, 'Clearing coupons, loyalty products from purchase session')
          this.cart = await purchaseService.fetchCleanUserRelatedPurchaseSession(
            this.cart.sessionId
          )
        } catch (err) {
          logInfo(
            log,
            'There was an error clearing purchaseSession coupons coupons, loyalty products'
          )
        }
      }
      logInfo(log, 'Cleared user data')
    },
    findCartProduct(productCartId: string) {
      return this.cart?.productsTotals.find(
        (cartProduct) => cartProduct.productCartId === productCartId
      )
    },
    setAopCouponProducts(products?: ProductLiteCategorized[]) {
      this.aopCouponProducts = products
    },
    setCardMethodUnavailable(val: boolean) {
      this.cardMethodUnavailable = val
    },
    async fetchLoyaltyTiers() {
      if (this.loyaltyTiers) return
      try {
        logInfo(log, 'Fetching async loyalty tiers')
        const loyaltyTiers = await catalogService.fetchLoyaltyCatalog()
        this.loyaltyTiers = loyaltyTiers
      } catch (error) {
        logInfo(log, 'Loyalty tiers could not be loaded')
        this.loyaltyTiers = []
      }
    },
    // user is soft, click on loyalty product, must validate im identity with QR
    setSoftUserValidationImIdentity(validating: boolean) {
      this.softUserValidationImIdentity = validating
    },
    // user is fullExp, inactivityIsShowed, on cart while redeeming points, shows ConfirmingIdentity
    setConfirmingIdentity(confirming: boolean) {
      this.confirmingIdentity = confirming
    },
    setInactivityIsShowed(isShowed: boolean) {
      this.inactivityIsShowed = isShowed
    }
  }
})
