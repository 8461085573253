<script lang="ts" setup>
import FullScreenLoader from './ui/FullScreenLoader.vue'
import ConfirmDialog from './ui/modal/ConfirmDialog.vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { createConfirmDialog } from 'vuejs-confirm-dialog'
import { useAppStore } from '@stores/app.store'
import { logInfo, logError, scopedLogger } from '@lib/utils/logUtils'
import { ConfirmDialogProps } from '@lib/models/modals'
import { useLocale } from '@composables/useLocale'
import { GTMContentGroup, LoginMethod } from '@lib/models/gtm'
import { LoginType, User } from '@lib/models/identification'
import { useGtm } from '@composables/useGtm'
import { useErrorApi } from '@composables/useErrorApi'
import { BackendCustomException } from '@lib/models/internal/errors'
import { parseQueryIntoLocationQuery } from '@lib/utils/utils'
import { aopService } from '@lib/services/backend/aop/aop.service'
import { purchaseService } from '@lib/services/backend/purchase/purchase.service'
import { identificationService } from '@lib/services/backend/identification/identification.service'

const scannerActiveRouteNames = [
  'attract',
  'prehome',
  'identification',
  'home',
  'category',
  'cart',
  'aop-coupon',
  'loyalty',
  'confirming-identity'
]

const log = scopedLogger('@component/ScannerKeepAlive.vue')

const store = useAppStore()
const { t } = useLocale()
const route = useRoute()
const router = useRouter()
const { trackLogin, trackRedeem, trackBeginCheckout } = useGtm()
const { logErrorApi } = useErrorApi()

const scanned = ref<string>()
const processing = ref<'identification' | 'coupon'>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { reveal, onConfirm, onCancel, close } = createConfirmDialog(ConfirmDialog as any)

const identificationErrorDialogProps = (
  type: 'loginError' | 'upgradingError'
): ConfirmDialogProps => {
  const tKey = type === 'loginError' ? 'identificationError' : 'identificationUpgradingError'
  const dialogProps = {
    title: t(`modals.${tKey}.title`),
    description: t(`modals.${tKey}.description`),
    primaryButton: t('actions.tryAgain'),
    secondaryButton: t('actions.cancel'),
    imageUrl: 'images/icons/error.svg',
    contentGroup:
      type === 'loginError'
        ? {
            cg: GTMContentGroup.primary.USER,
            cg2: GTMContentGroup.secondary.LOGIN,
            cg3: GTMContentGroup.tertiary.ERROR
          }
        : undefined
  }
  return dialogProps
}

const couponErrorDialogProps: ConfirmDialogProps = {
  title: t('modals.aopCouponError.title'),
  description: t('modals.aopCouponError.description'),
  primaryButton: t('actions.tryAgain'),
  secondaryButton: t('actions.cancel'),
  imageUrl: 'images/icons/error.svg'
}

const couponErrorWithoutSessionDialogProps: ConfirmDialogProps = {
  title: t('modals.aopCouponWithoutSession.title'),
  description: t('modals.aopCouponWithoutSession.description'),
  primaryButton: t('actions.accept'),
  secondaryButton: undefined,
  imageUrl: 'images/icons/info.svg'
}

const identificationSuccesDialogProps = (userName: string) => {
  return {
    title: t('modals.identificationSuccess.title', { user: userName }),
    description: t('modals.identificationSuccess.description'),
    primaryButton: t('actions.continue'),
    secondaryButton: undefined,
    imageUrl: 'images/mcd.png',
    contentGroup: {
      cg: GTMContentGroup.primary.USER,
      cg2: GTMContentGroup.secondary.LOGIN,
      cg3: GTMContentGroup.tertiary.WELCOME
    }
  }
}

const qrChangeAccountDialogProps: ConfirmDialogProps = {
  title: t('modals.qrChangeAccount.title'),
  description: t('modals.qrChangeAccount.description'),
  primaryButton: t('actions.keepAccount'),
  secondaryButton: t('actions.changeAccount'),
  imageUrl: 'images/icons/info.svg'
}

const onScannedCode = async (code?: string) => {
  if (!code || scanned.value) return

  // check valid route to continue scanning
  let canContinue = scannerActiveRouteNames.includes(route.name as string)
  if (route.name === 'checkout') {
    // check checkout only first step is available
    canContinue = route.query.step === 'payment-method'
  }
  if (!canContinue) return

  logInfo(log, 'Scanned code => ***' + code + '***')
  scanned.value = code

  // check code type
  if (scanned.value.includes('FF99') || scanned.value.includes('|')) {
    const [mcId] = scanned.value.split(/\||FF99/g)
    if (!mcId) {
      reset()
      return
    }
    // pass mcId and scanned full code (qr)
    await scanProcessIdentification(mcId, scanned.value)
  } else {
    const regex = new RegExp(/^[A-Z0-9]{9}$/gm)
    let invalidStateCoupon = false

    if (!regex.test(scanned.value)) {
      logInfo(log, 'Seems scanned code is not a valid coupon code')
      invalidStateCoupon = true
    }

    if (!store.cart) {
      // no session available yet
      logInfo(log, 'Session is not started yet, user cannot try to apply coupon')
      reveal(couponErrorWithoutSessionDialogProps).then(() => {
        processing.value = undefined
      })
      onConfirm(async () => {
        if (route.name === 'attract') await router.push({ name: 'prehome' })
      })
      invalidStateCoupon = true
    }
    if (route.name === 'confirming-identity') {
      logInfo(log, 'Coupon cannot be scanned on confirming-identity')
      invalidStateCoupon = true
    }

    if (invalidStateCoupon) {
      reset()
      return
    }
    await scanProcessCoupon({
      coupon: scanned.value,
      mcId: scanned.value.substring(3),
      sessionId: store.cart!.sessionId
    })
  }
}

async function eventListenerScan(ev: CustomEvent) {
  const { scanCode } = ev.detail
  await onScannedCode(scanCode)
}

const removeListener = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document.removeEventListener<any>('scan', eventListenerScan, true)
}

const identificationError = async (type: 'loginError' | 'upgradingError') => {
  const isConfirmingAccountState = store.confirmingIdentity
  reveal(identificationErrorDialogProps(type)).then(() => {
    processing.value = undefined
  })
  onCancel(async () => {
    // only terminate process
    reset()
    // when confirming and cancel, go to home (logout, and clear cart is done)
    if (isConfirmingAccountState) await router.push({ name: 'home' })
  })
  onConfirm(async () => {
    // go to identification (if not is there already)
    if (route.name !== 'identification') {
      // when confirming prevent from identification to go back to 'confirming-identity'
      const from = isConfirmingAccountState ? '/home' : route.path
      const name = isConfirmingAccountState ? 'home' : (route.name as string)
      await router.push({
        name: 'identification',
        query: { from, name }
      })
    }
    reset()
  })
}

const confirmingAccountError = async () => {
  const dialogProps = {
    title: t('modals.identificationUpgradingError.title'),
    description: t('modals.identificationUpgradingError.description'),
    primaryButton: t('actions.accept'),
    secondaryButton: undefined,
    imageUrl: 'images/icons/error.svg',
    contentGroup: undefined
  }
  reveal(dialogProps).then(() => {
    processing.value = undefined
  })
  onConfirm(async () => {
    // terminate process, go cart
    reset()
    await router.push({ name: 'cart' })
  })
}

const navigateRedirectTo = async () => {
  if (route.query.redirectTo) {
    const url = decodeURIComponent(route.query.redirectTo as string)
    const [path, query] = url.split('?')
    await router.push({
      path,
      query: query ? parseQueryIntoLocationQuery(query) : undefined
    })
  }
}

const navigateAfterWelcome = async ({ toHome }: { toHome: boolean }) => {
  if (toHome) {
    return await router.push({
      name: 'home'
    })
  }

  // if coming from attract go to prehome
  if (route.query.name === 'attract' || route.name === 'attract') {
    await router.push({
      name: 'prehome'
    })
  }
  // if on identification go to path from where you enter identification
  else if (route.name === 'identification') {
    const path = route.query.from ? (route.query.from as string) : '/home'
    await router.push({
      path
    })
  }
}

const upgradeIdentification = async ({ qr, logKey }: { qr: string; logKey: string }) => {
  try {
    processing.value = 'identification'
    logInfo(log, `${logKey}:SAME_USER, try to upgrade to IM login`)
    store.setUser({ user: await identificationService.upgradeLogin({ qr }) })
    if (store.softUserValidationImIdentity) {
      // navigate to redirectTo if exist
      await navigateRedirectTo()
    }
  } catch (error) {
    logError(log, `${logKey}:SAME_USER Could not upgrade user login`)
    await identificationError('upgradingError')
  }
}

const logoutAndCleanupOnChangeAccount = async (logKey: string) => {
  try {
    processing.value = 'identification'
    logInfo(log, `${logKey}:OTHER_USER, change account, try to logout and cleanup session`)
    await store.clearUserData({ cleanSession: false })
    if (store.cart)
      store.cart = await purchaseService.fetchCleanupPurchaseSession(store.cart.sessionId)
  } catch (error) {
    logError(log, `${logKey}:OTHER_USER, Could not logout or cleanup session`)
  }
}

const alreadyLoggedProcessSoftValidationIdentity = async (
  mcId: string,
  qr: string,
  user: User
): Promise<boolean> => {
  const isSameUser = user.mcId === mcId
  // SAME User
  if (isSameUser) {
    if (user.loginType === LoginType.IM) {
      // WIP - must not enter by here, if confirming has to be a soft user ...
      logInfo(log, 'VALIDATION_IDENTITY:SAME_USER, already logged with IM')
      // dont try to login currently logged user already with IM ...
      // navigate to redirectTo if exist
      await navigateRedirectTo()
      return false
    } else {
      // @action:upgrade to IM login
      await upgradeIdentification({ qr, logKey: 'VALIDATION_IDENTITY' })
      return false
    }
  }

  // OTHER User
  const { isCanceled } = await reveal(qrChangeAccountDialogProps)
  close() // when using global onConfirm, onCancel is needed if using await?
  if (!isCanceled) {
    logInfo(log, 'VALIDATION_IDENTITY:OTHER_USER keep account')
    await navigateAfterWelcome({ toHome: false })
    return false // @action:keep account
  }

  // @action:change account
  // logout, clear cart, login with QR (after) in regular process
  await logoutAndCleanupOnChangeAccount('VALIDATION_IDENTITY')
  return true
}

const alreadyLoggedProcessIdentification = async (
  mcId: string,
  qr: string,
  user: User
): Promise<boolean> => {
  const isSameUser = user.mcId === mcId
  // SAME User
  if (isSameUser) {
    if (user.loginType === LoginType.IM) {
      logInfo(log, 'ALREADY_LOGGED:SAME_USER, already logged with IM')
      // dont try to login currently logged user already with IM ...
      return false
    } else {
      // @action:upgrade to IM login
      await upgradeIdentification({ qr, logKey: 'ALREADY_LOGGED' })
      return false
    }
  }

  // OTHER User
  const { isCanceled } = await reveal(qrChangeAccountDialogProps)
  close() // when using global onConfirm, onCancel is needed if using await?
  if (!isCanceled) {
    logInfo(log, 'ALREADY_LOGGED:OTHER_USER keep account')
    return false // @action:keep account
  }

  // @action:change account
  // logout, clear cart, login with QR (after) in regular process
  await logoutAndCleanupOnChangeAccount('ALREADY_LOGGED')
  return true
}

const alreadyLoggedProcessConfirmingIdentity = async (
  mcId: string,
  qr: string,
  user: User
): Promise<boolean> => {
  const isSameUser = user.mcId === mcId
  // SAME User
  if (isSameUser) {
    if (user.loginType === LoginType.IM) {
      try {
        logInfo(log, 'CONFIRMING_IDENTITY:SAME_USER, try to confirming current login with IM')
        store.setUser({ user: await identificationService.confirmLogin({ qr }) })
        logInfo(log, 'CONFIRMING_IDENTITY:SAME_USER, confirmed indentity for user')
        store.setInactivityIsShowed(false) // reset inactivity
        // advance to checkout
        trackBeginCheckout()
        if (!store.crossSellingCheckoutIsViewed)
          await router.push({ name: 'crossSelling', query: { checkout: 'true' } })
        else await router.push({ name: 'checkout' })
      } catch (error) {
        logError(log, 'CONFIRMING_IDENTITY:SAME_USER, Could not confirm current login with IM')
        await confirmingAccountError()
      }
    }
    return false
  }

  // OTHER User
  const { isCanceled } = await reveal(qrChangeAccountDialogProps)
  close() // when using global onConfirm, onCancel is needed if using await?
  if (!isCanceled) {
    logInfo(log, 'CONFIRMING_IDENTITY:OTHER_USER keep account')
    await router.push({ name: 'cart' })
    return false // @action:keep account
  }

  // @action:change account
  // logout, clear cart, login with QR (after) in regular process
  await logoutAndCleanupOnChangeAccount('CONFIRMING_IDENTITY')
  store.setInactivityIsShowed(false) // reset inactivity
  return true
}

const scanProcessIdentification = async (mcId: string, qr: string) => {
  const userLogged = store.user
  let mustContinue = true

  if (userLogged) {
    // check softUserValidationImIdentity
    if (store.softUserValidationImIdentity) {
      logInfo(log, 'VALIDATION_IDENTITY with qr')
      mustContinue = await alreadyLoggedProcessSoftValidationIdentity(mcId, qr, userLogged)
    } else if (store.confirmingIdentity) {
      // check confirmingIdentity (user blocked on cart)
      logInfo(log, 'CONFIRMING_IDENTITY with qr')
      mustContinue = await alreadyLoggedProcessConfirmingIdentity(mcId, qr, userLogged)
    } else {
      // check same user or other on scan QR without softUserValidationImIdentity
      mustContinue = await alreadyLoggedProcessIdentification(mcId, qr, userLogged)
    }
  }

  if (!mustContinue) {
    reset()
    return
  }

  logInfo(log, 'Starting to identificate with qr, user with mcId = ' + mcId)

  processing.value = 'identification'
  const user = await store.logInUserAction({ qr })

  if (user) {
    reveal(identificationSuccesDialogProps(user.name)).then(() => {
      processing.value = undefined
    })
    onConfirm(async () => {
      trackLogin(LoginMethod.QR_IDENTIFICATION)
      await navigateAfterWelcome({
        toHome: store.softUserValidationImIdentity || store.confirmingIdentity
      })
      reset()
    })
  } else {
    await identificationError('loginError')
  }
}

const onCancelCouponError = async () => {
  if (!store.cart) {
    await router.push({ name: 'prehome' })
  } else await router.push({ name: 'home' })
}

const couponError = async (type: 'aopCouponAlreadyUsed' | 'aopCouponError' = 'aopCouponError') => {
  const dialogProps = {
    ...couponErrorDialogProps,
    title: t(`modals.${type}.title`),
    description: t(`modals.${type}.description`)
  }
  reveal(dialogProps).then(() => {
    reset()
  })
  onCancel(async () => {
    await onCancelCouponError()
  })
  onConfirm(async () => {
    await router.push({ name: 'aop-coupon' })
  })
}

const scanProcessCoupon = async ({
  coupon,
  mcId,
  sessionId
}: {
  coupon: string
  mcId: string
  sessionId: string
}) => {
  logInfo(log, 'Starting coupon process')

  const isUserLogged = !!store.user
  // start logging process too
  if (!isUserLogged) {
    processing.value = 'identification'
    logInfo(log, 'Starting to identificate with mcId = ' + mcId)
    const user = await store.logInUserAction({ mcId })
    if (!user) {
      logError(log, 'User could not be logged from coupon')
      await couponError()
      return
    }
  }

  try {
    processing.value = 'coupon'
    const { products } = await aopService.aopCheckCoupon(coupon, sessionId)
    if (!products.length) {
      logInfo(log, 'Coupon has no products')
      await couponError()
      return
    }
    // single product
    trackRedeem({ coupon, method: LoginMethod.QR_IDENTIFICATION })
    if (products.length === 1) {
      const { idCategory, identifier } = products[0]
      // navigate to product detail
      logInfo(log, 'Navigating to product detail with aopCoupon = ' + coupon)
      await router.push({
        name: 'product',
        params: { categoryId: idCategory, productId: identifier },
        query: { aopCoupon: coupon }
      })
    } else {
      // several products
      store.setAopCouponProducts(products)
      logInfo(log, 'Navigating to aop-coupon-products with aopCoupon = ' + coupon)
      // navigate
      await router.push({
        name: 'aop-coupon-products',
        query: { aopCoupon: coupon, fromPath: route.path }
      })
    }
    reset()
  } catch (error) {
    const { errorKey } = logErrorApi('scanProcessCoupon', error, log)
    if (errorKey === 'unauthorized') return
    await couponError(
      errorKey === BackendCustomException[610] ? 'aopCouponAlreadyUsed' : 'aopCouponError'
    )
  }
}

const reset = () => {
  scanned.value = undefined
  processing.value = undefined
}

onMounted(() => {
  removeListener()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document.addEventListener<any>('scan', eventListenerScan, true)
})

onBeforeUnmount(() => {
  removeListener()
})
</script>

<template>
  <span data-test="scanner-keep-alive" class="absolute top-0 left-0">
    <!-- Processing -->
    <transition name="fade-b" mode="out-in">
      <FullScreenLoader
        v-if="processing"
        :title="t(`${processing}.processing`)"
        lottie-key="loader-fries"
      />
    </transition>
  </span>
</template>
