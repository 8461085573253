import { categoriesDto } from '@lib/models/category'
import { BackendService } from '../backend.service'
import { scopedLogger } from '@lib/utils/logUtils'
import { LogFunctions } from 'electron-log'
import { productDto, crossSellingProductsDto } from '@lib/models/product'
import { loyaltyTiers } from '@lib/models/loyalty'

class CatalogService extends BackendService {
  protected logger: LogFunctions

  constructor() {
    super('catalog')

    this.logger = scopedLogger('@services/backend/catalog')
  }

  async fetchCatalog() {
    return await this.request(
      {
        method: 'GET',
        url: '/catalog/lite'
      },
      categoriesDto,
      this.logger
    )
  }

  async fetchProduct({ categoryId, productId }: { categoryId: string; productId: string }) {
    return await this.request(
      {
        method: 'GET',
        url: `/catalog/product/${productId}`,
        params: {
          category: categoryId
        }
      },
      productDto,
      this.logger
    )
  }

  async fetchLoyaltyCatalog() {
    return await this.request(
      {
        method: 'GET',
        url: '/catalog/loyalty'
      },
      loyaltyTiers,
      this.logger
    )
  }
  async fetchCrossSellingProducts(productList: string) {
    return await this.request(
      {
        method: 'GET',
        url: `/catalog/crossselling?productsList=${productList}`
      },
      crossSellingProductsDto,
      this.logger
    )
  }
}

export const catalogService = new CatalogService()
