import { orderDto, OrderInput } from '@lib/models/order'
import { BackendService } from '../backend.service'
import { scopedLogger } from '@lib/utils/logUtils'
import { LogFunctions } from 'electron-log'
import { zAny } from '@lib/models/common'

class OrdersService extends BackendService {
  protected logger: LogFunctions

  constructor() {
    super('orders')

    this.logger = scopedLogger('@services/backend/orders')
  }

  async createOrder(data: OrderInput) {
    return await this.request(
      {
        method: 'POST',
        url: `/orders`,
        timeout: 20 * 1000, // 20s
        data
      },
      orderDto,
      this.logger
    )
  }

  async fetchOrder(orderId: string, signal?: AbortSignal) {
    return await this.request(
      {
        method: 'GET',
        url: `/orders/${orderId}`,
        signal
      },
      orderDto,
      this.logger
    )
  }

  async fetchOrderPayment(orderId: string) {
    return await this.request(
      {
        method: 'POST',
        url: '/orders/payment',
        data: {
          orderId
        }
      },
      orderDto,
      this.logger
    )
  }

  async fetchOrderPOS(orderId: string) {
    return await this.request(
      {
        method: 'POST',
        url: '/orders/pos',
        timeout: 60 * 1000, // 60s
        data: {
          orderId
        }
      },
      orderDto,
      this.logger
    )
  }

  // user reset checkout after an error happens after order is created
  async fetchDisposeOrder(orderId: string) {
    return await this.request(
      {
        method: 'POST',
        url: `/orders/dispose/${orderId}`
      },
      zAny,
      this.logger
    )
  }
}

export const ordersService = new OrdersService()
