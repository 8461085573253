<script lang="ts" setup>
import { useSlots, ref, onMounted } from 'vue'
import BtnCancelOrder from './actions/BtnCancelOrder.vue'
import { useLocale } from '@composables/useLocale'
import { getAppVersion } from '@lib/utils/electronUtils'

const { t } = useLocale()
const slots = useSlots()

const version = ref()
const versionVisible = ref(false)

onMounted(() => {
  getAppVersion().then((vs: string) => {
    version.value = vs
  })
})
</script>

<template>
  <slot></slot>
  <section data-test="footer" class="w-full flex flex-row gap-[1.48vw]">
    <!-- Identification user container -->
    <slot name="identification"></slot>

    <div class="flex-1 mr-[8.89vw] flex flex-col gap-[0.83vh] justify-end">
      <!-- Primary button section -->
      <slot name="primary-actions"></slot>

      <!-- Secondary button section (slot added or by default) -->
      <slot v-if="slots['secondary-actions']" name="secondary-actions"></slot>
      <div v-else class="flex flex-row gap-[1.48vw] items-center justify-end">
        <BtnCancelOrder />
      </div>
      <!-- Kcal disclaimer -->
      <p class="text-xsmall mb-[0.83vh]" @click="versionVisible = !versionVisible">
        {{ t('footer.disclaimer') }}
        <b v-if="versionVisible && version" class="float-right">v{{ version }}</b>
      </p>
    </div>
  </section>
</template>
