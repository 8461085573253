import { LogFunctions } from 'electron-log'
import { getErrorType, isAxiosApiError } from '../types/typesUtils'
import { logError } from '@lib/utils/logUtils'
import { BackendCustomException, CustomExceptionKey } from '@lib/models/internal/errors'

export const useErrorApi = () => {
  const logErrorApi = (
    fnName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    log: LogFunctions
  ): { errorKey: CustomExceptionKey; errorType: 'ZOD' | 'AXIOS' | 'LOGIC' } => {
    const errorType = getErrorType(error)
    if (errorType === 'LOGIC') logError(log, `ERR LOGIC: ${fnName} ` + error.message)
    else if (errorType === 'ZOD') logError(log, `ERR ZOD: ${fnName}`)
    else logError(log, `ERR AXIOS: ${fnName} ` + error.message)
    const errorKey = getErrorKey(error)
    return { errorKey, errorType }
  }

  const getErrorKey = (error: unknown): CustomExceptionKey => {
    if (isAxiosApiError(error)) {
      const status = error.response?.status ?? 500
      if (status === 401) return 'unauthorized'
      if (BackendCustomException[status]) return BackendCustomException[status]
    }
    return 'unknown'
  }

  return { logErrorApi, getErrorKey }
}
