import { createWebHashHistory, createRouter } from 'vue-router'

const SplashLoader = () => import('@components/Splash/SplashLoader.vue')
const Attract = () => import('@components/Attract/Attract.vue')
const Prehome = () => import('@components/Prehome/Prehome.vue')
const Home = () => import('@components/Home/Home.vue')
const Category = () => import('@components/Menu/Category.vue')
const ProductDetail = () => import('@components/Menu/product-detail/ProductDetail.vue')
const CrossSelling = () => import('@components/Cart/CrossSelling.vue')
const Cart = () => import('@components/Cart/Cart.vue')
const EditProduct = () => import('@components/Menu/product-detail/EditProduct.vue')
const Checkout = () => import('@components/Checkout/Checkout.vue')
const Identification = () => import('@components/Identification/Identification.vue')
const Fiscal = () => import('@components/Fiscal/Fiscal.vue')
const CouponProducts = () => import('@components/AOP/CouponProducts.vue')
const Coupon = () => import('@components/AOP/Coupon.vue')
const Loyalty = () => import('@components/Loyalty/Loyalty.vue')
const ConfirmingIdentity = () => import('@components/ConfirmingIdentity/ConfirmingIdentity.vue')

const routes = [
  {
    path: '/',
    name: 'splash',
    component: SplashLoader,
    meta: { mode: 'out-in', layout: 'BlankLayout' }
  },
  {
    path: '/attract',
    name: 'attract',
    component: Attract,
    meta: { mode: 'out-in', layout: 'AttractLayout' }
  },
  {
    path: '/identification',
    name: 'identification',
    component: Identification,
    props: (route) => ({ from: route.query.from, name: route.query.name }),
    meta: { mode: 'out-in', layout: 'PickUpSelectionLayout' }
  },
  {
    path: '/prehome',
    name: 'prehome',
    component: Prehome,
    meta: { mode: 'out-in', layout: 'PickUpSelectionLayout' }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { mode: 'out-in', layout: 'HomeLayout' }
  },
  // menu category
  {
    path: '/menu/:categoryId',
    name: 'category',
    component: Category,
    meta: {
      transition: 'fade-b',
      mode: 'out-in',
      layout: 'HomeLayout'
    }
  },
  // menu product
  {
    path: '/menu/:categoryId/:productId',
    name: 'product',
    component: ProductDetail,
    props: (route) => ({
      fromCategory: route.query.cat === 'true',
      aopCoupon: route.query.aopCoupon,
      isLoyalty: route.query.loyalty === 'true',
      productType: route.query.productType
    }),
    meta: { mode: 'out-in', layout: 'BlankLayout' }
  },
  // crossSelling
  {
    path: '/crossselling',
    name: 'crossSelling',
    component: CrossSelling,
    props: (route) => ({
      checkout: route.query.checkout === 'true'
    }),
    meta: { mode: 'out-in', layout: 'BlankLayout' }
  },
  // cart
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: { mode: 'out-in', layout: 'BlankLayout' }
  },
  {
    path: '/cart/:productCartId',
    name: 'edit-product',
    component: EditProduct,
    meta: { mode: 'out-in', layout: 'BlankLayout' }
  },
  // checkout
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
    meta: { mode: 'out-in', layout: 'BlankLayout' }
  },
  // fiscal
  {
    path: '/fiscal',
    name: 'fiscal',
    component: Fiscal,
    meta: { mode: 'out-in', layout: 'BlankLayout' }
  },
  // aop coupons
  {
    path: '/aop/coupon',
    name: 'aop-coupon',
    component: Coupon,
    meta: { mode: 'out-in', layout: 'PickUpSelectionLayout' }
  },
  {
    path: '/aop/coupon-products',
    name: 'aop-coupon-products',
    component: CouponProducts,
    props: (route) => ({ aopCoupon: route.query.aopCoupon, fromPath: route.query.fromPath }),
    meta: { mode: 'out-in', layout: 'BlankLayout' }
  },
  // loyalty
  {
    path: '/loyalty',
    name: 'loyalty',
    component: Loyalty,
    meta: {
      transition: 'fade-b',
      mode: 'out-in',
      layout: 'HomeLayout'
    }
  },
  // confirming identity
  {
    path: '/confirming-identity',
    name: 'confirming-identity',
    component: ConfirmingIdentity,
    meta: {
      mode: 'out-in',
      layout: 'BlankLayout'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
