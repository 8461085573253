import { z } from 'zod'
import { saleTypeUnion } from './common'

/* Payment Method */
export const PaymentMethod = {
  CARD: 'card',
  IN_PERSON: 'inPerson',
  LOYALTY: 'loyalty'
} as const

export const paymentMethodUnion = z.union([
  z.literal(PaymentMethod.CARD),
  z.literal(PaymentMethod.IN_PERSON),
  z.literal(PaymentMethod.LOYALTY)
])

export type PaymentMethodType = z.infer<typeof paymentMethodUnion>

/* Withdrawal Method */
export const WithdrawalMethod = {
  AT_TABLE: 'ealm',
  AT_COUNTER: 'mop'
} as const

export const withdrawalMethodUnion = z.union([
  z.literal(WithdrawalMethod.AT_TABLE),
  z.literal(WithdrawalMethod.AT_COUNTER)
])

export type WithdrawalMethodType = z.infer<typeof withdrawalMethodUnion>

// MOP pos
const orderMOPPos = z.object({
  orderNumber: z.number().optional()
})

/* Order */
export const orderInput = z.object({
  sessionId: z.string(),
  mcId: z.string().optional(),
  paymentMethod: paymentMethodUnion,
  area: withdrawalMethodUnion.optional(),
  serviceNumber: z.string().optional(),
  fiscalFields: z.record(z.string()).optional()
})

export type OrderInput = z.infer<typeof orderInput>

// order.status
export const OrderStatus = {
  VALIDATION_PENDING: 'VALIDATION_PENDING',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_DONE: 'PAYMENT_DONE',
  FLEX_SENDING: 'FLEX_SENDING',
  FLEX_ERROR: 'FLEX_ERROR',
  FLEX_RECEIVED: 'FLEX_RECEIVED',
  FLEX_ACCEPTED: 'FLEX_ACCEPTED',
  FLEX_STAGING: 'FLEX_STAGING',
  FLEX_PREPARING: 'FLEX_PREPARING',
  FLEX_READY: 'FLEX_READY',
  FLEX_DELIVERING: 'FLEX_DELIVERING',
  FLEX_DELIVERED: 'FLEX_DELIVERED',
  FLEX_CANCELLED: 'FLEX_CANCELLED',
  CANCELLED: 'CANCELLED',
  FINISHED: 'FINISHED',
  PICKUP_EXPIRED: 'PICKUP_EXPIRED'
} as const

// order.subStatus (extends OrderStatus)
export const OrderSubstatus = {
  // ecommerce
  PAYMENT_PENDING_COUNTER: 'PAYMENT_PENDING_COUNTER',
  PAYMENT_DONE_MCDIA: 'PAYMENT_DONE_MCDIA',
  PAYMENT_DONE_MCDIA_3DAYS: 'PAYMENT_DONE_MCDIA_3DAYS',
  PAYMENT_DONE_MCDIA_INDATE: 'PAYMENT_DONE_MCDIA_INDATE',
  PAYMENT_DONE_MCDIA_EXPIRED: 'PAYMENT_DONE_MCDIA_EXPIRED',
  // adk
  PAYMENT_CASH: 'PAYMENT_CASH',
  PAYMENT_ERROR_SOMETHING_WRONG: 'SOMETHING_WRONG',
  PAYMENT_ERROR_CANT_FINISH: 'CANT_FINISH_PAYMENT'
} as const

export const orderDto = z.object({
  orderId: z.string(),
  // status
  status: z.string(),
  subStatus: z.string(),
  // pos
  pos: orderMOPPos.optional(), // pos.orderNumber is orderId from flex (to show user)
  // saleType
  type: saleTypeUnion,
  // other
  paymentMethod: paymentMethodUnion,
  area: withdrawalMethodUnion.optional(),
  serviceNumber: z.string().optional()
})

export type Order = z.infer<typeof orderDto>
