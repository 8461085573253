<script lang="ts" setup>
import { computed } from 'vue'
import LoyaltyUserBlock from '@components/Identification/LoyaltyUserBlock.vue'
import { useGtm } from '@composables/useGtm'
import { useLocale } from '@composables/useLocale'
import { useAppStore } from '@stores/app.store'
import { useRoute } from 'vue-router'

const { t } = useLocale()
const route = useRoute()
const store = useAppStore()
const { trackMenuButton } = useGtm()

const routes = computed(() => {
  const routes = [
    {
      name: 'home',
      icon: 'home'
    }
  ]
  if (store.loyaltyTiers?.length) {
    routes.push({
      name: 'loyalty',
      icon: 'loyalty'
    })
  }
  routes.push({
    name: 'aop-coupon',
    icon: 'coupon'
  })

  return routes
})

const isRouteNameActive = (routeName: string) => routeName === route.name

const getRouteImgSrc = (route: { name: string; icon: string }) => {
  const isActive = isRouteNameActive(route.name)
  const iconName = isActive ? `${route.icon}-active` : route.icon
  return `images/navigation/${iconName}.svg`
}
</script>

<template>
  <section
    data-test="aside-menu"
    class="mb-[2.50vh] py-[0.83vh] flex flex-col gap-[0.83vh] bg-white rounded shadow-elevation-down"
  >
    <LoyaltyUserBlock
      v-if="store.user"
      :user="store.user"
      :locale="store.locale"
      :decimals="store.config?.decimalsQty ?? 0"
    />
    <RouterLink
      v-for="menuRoute in routes"
      :key="menuRoute.name"
      v-ripple
      :data-test="`link-aside-${menuRoute.name}`"
      class="cursor-pointer w-full py-[0.21vh] px-[1.48vw] flex flex-row items-center gap-[2.2vw]"
      :to="{ name: menuRoute.name }"
    >
      <div class="flex flex-row items-center gap-[2.2vw]" @click="trackMenuButton(menuRoute.name)">
        <img :src="getRouteImgSrc(menuRoute)" :alt="menuRoute.name" class="h-[2.50vh] w-auto" />
        <p class="text-small">{{ t(`navigation.${menuRoute.name}`) }}</p>
      </div>
    </RouterLink>
  </section>
</template>

<style scoped>
.router-link-exact-active {
  @apply relative bg-adk-secondaryIvory;
}

.router-link-exact-active::before {
  @apply absolute content-[''] h-full top-0 left-0 w-[0.25vw] bg-adk-main;
}

.router-link-exact-active p {
  @apply font-bold;
}
</style>
