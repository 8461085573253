import { z } from 'zod'

export interface LoginInput {
  mcId?: string
  qr?: string
}

/* LoginType */
export const LoginType = {
  IM: 'IM',
  SOFT: 'API_HUB'
} as const

export const loginTypeUnion = z.union([z.literal(LoginType.IM), z.literal(LoginType.SOFT)])

export const customerDto = z.object({
  mcId: z.string(),
  name: z.string(),
  loyalty: z.boolean(),
  points: z.number(),
  loginType: loginTypeUnion.optional().default(LoginType.IM)
})

export type User = z.infer<typeof customerDto>
